.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

// Temporalmente aqui hasta que se agregue a la libreria
.status-wrapper-pr #status.g-outlined-select .g-outlined-select__control {
  height: 65px;
}
.status-wrapper-pr #status.g-outlined-select .g-outlined-select__single-value {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.status-wrapper-pr #status.g-outlined-select .g-outlined-select__option {
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  height: 55px;
  line-height: 40px;
}

.status-wrapper-pr #status.g-outlined-select .g-outlined-select__option:hover {
  background-color: #F2F8FE;
}
.status-wrapper-pr #status.g-outlined-select .g-outlined-select__option.g-outlined-select__option--is-selected  {
  background-color: #F2F8FE;
}

.g-radio-button label {
  color: #1e1e1e;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
}

.g-radio-button [type='radio']:checked,
.g-radio-button [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.g-radio-button [type='radio']:checked + label,
.g-radio-button [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.g-radio-button [type='radio']:checked + label:before,
.g-radio-button [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.g-radio-button [type='radio']:checked + label:after,
.g-radio-button [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--primary);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.g-radio-button [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.g-radio-button [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.g-table-hidden-x .table-responsive {
  overflow-x: hidden;
}